.form_ {
    display: flex;
    flex-direction: column;
}
input:focus, textarea:focus {
    outline: none;
    border-bottom: 1px solid #009874;
}
input[type=checkbox] {
    accent-color: #009874;
}
.enquiry_label {
    color: #fff;
    font-size: 14px;
    margin-left: 20px;
    transition: all 0.3s ease;
}
.terms {
    margin-left: 10px;
    color: #2d2d2d;
    font-size: 16px;
    font-weight: 500;
}
.main_labels {
    color: #2d2d2d;
    font-size: 16px;
    margin: 0px;
}
.main_input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #2d2d2d;
    background-color: transparent;
    color: #6b6b6b;
    font-size: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease;
    margin: 0px;
}
.select_inputs {
    width: 100%;
    border: none;
    border-bottom: 1px solid #2d2d2d;
    background-color: transparent;
    color: #6b6b6b;
    font-size: 14px;
    box-sizing: border-box;
    margin: 5px 0px 0px 0px;
}
.main_input::placeholder {
    color: #2d2d2d;
    font-size: 16px;
}
.main_input:invalid[focused="true"]{
    border-bottom: 1px solid red;
}
.main_input:invalid[focused="true"] ~ span{
    display: block;
}
.main_message[isValid="false"]{
    border-bottom: 1px solid red;
}
.main_message[isValid="false"] ~ span{
    display: block;
}
.main_message {
    width: 100%;
    border: 1px solid #d990be;
    padding: 10px 15px;
    background-color: transparent;
    color: #ffffff;
    transition: all 0.3s ease;
}
.btn_submit {
    width: 180px;
    background-color: #009874;
    color: #f2f2f2;
    font-size: 14px;
    font-weight: 900;
    padding: 10px;
    border: none;
    transition: all 0.3s ease;
}
.btn_submit:hover {
    background-color: #2d2d2d;
}
.form_error_msg {
    font-size: 14px;
    padding: 3px;
    color: rgb(248, 40, 40);
    display: none;
}
@media only screen and (max-width: 768px) {
    .enquiry_label {
        font-size: 16px;
        margin-left: 20px;
    }
    .main_input {
        padding: 15px 15px;
    }
    .main_message {

    }
    .btn_submit {
        width: 100%;
        height: 60px;
        margin-top: 30px;
    }
    .main_form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  }