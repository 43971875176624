a {
    text-decoration: none;
}
a:link { text-decoration: none; }

a:visited { text-decoration: none; }

a:hover { text-decoration: none; }

a:active { text-decoration: none; }

a:focus {
    outline: none !important;
  }
.IgVideo {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }