form {
    width: 100%;
    display: flex;
    align-items: center;
}
#emailInput {
    width: 230px;
    height: 40px;
    box-sizing: border-box;
    border: 2px solid #2d2d2d;
    background-color: transparent;
    color: #2d2d2d;
    font-size: 15px;
    font-weight: 400;
    padding-left: 15px;
}
#emailInput:focus {
    outline: none;
    border: 1px solid #009874;
}
#emailInput:invalid[focused="true"]{
    border: 1px solid red;
}
#submitEmailBtn {
    width: 100px;
    height: 40px;
    box-sizing: border-box;
    border: 2px solid #009874;
    background-color: #009874;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
@media only screen and (max-width: 768px) {
    form {
        flex-direction: column;
    }
    #emailInput {
        width: 100%;
        height: 60px;
        font-size: 15px;
        font-weight: 400;
        padding-left: 15px;
        margin-bottom: 20px;
    }
    #submitEmailBtn {
        width: 100%;
        height: 60px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 2px;
    }
  }